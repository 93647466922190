import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { getRandomObjectsFromArray } from "@utils"
import QuoteLeft from "@svg/ui/quote-left.svg"

export const Employee = () => {
  const data = useStaticQuery(query)
  const { allWpTeams } = data
  const filteredEmployees = allWpTeams.edges.filter((employee) => employee.node.profile.isDisplayedOnCareers === true)
  const randomizedFilteredEmployees = getRandomObjectsFromArray(filteredEmployees, 3)

  return (
    <>
      <div className="container flex justify-center">
        <div data-aos="fade-right" className="lg:w-10/12 grid grid-cols-4 sm:grid-cols-min-8-1fr gap-x-2 lg:gap-x-4">
          <div className="z-1 col-span-4 sm:col-span-1 flex items-center">
            <div className="z-0 rounded-full w-38 h-38 lg:w-50 lg:h-50 sm:mr-4 lg:mr-6 overflow-hidden">
              {randomizedFilteredEmployees[0].node.profile.testimonial?.localFile && (
                <GatsbyImage
                  className="h-full"
                  image={getImage(randomizedFilteredEmployees[0].node.profile.testimonial.localFile)}
                  alt={randomizedFilteredEmployees[0].node.title}
                />
              )}
            </div>
          </div>
          <div className="col-span-3 sm:col-span-6 lg:col-span-5 xl:col-span-4 mt-9 sm:mt-0">
            <div className="flex fill-current text-gray-darkest" style={{ width: "20px", height: "20px" }}>
              <QuoteLeft />
            </div>
            <p
              className="careers-employee-quote mt-4"
              dangerouslySetInnerHTML={{ __html: randomizedFilteredEmployees[0].node.profile.love }}
            />
            <h3 className="text-clamp-18-26 mt-clamp-8-11">{randomizedFilteredEmployees[0].node.title}</h3>
            <h4 className="font-book font-medium text-clamp-14-22">
              {randomizedFilteredEmployees[0].node.profile.title}
            </h4>
          </div>
        </div>
      </div>
      <div className="container flex justify-center mt-clamp-18-28">
        <div data-aos="fade-left" className="lg:w-10/12 grid grid-cols-4 sm:grid-cols-min-8-1fr gap-x-2 lg:gap-x-4">
          <div className="z-1 flex justify-center col-span-4 sm:col-start-3 lg:col-start-4 xl:col-start-5 sm:col-span-1 flex items-center">
            <div className="z-0 rounded-full w-38 h-38 lg:w-50 lg:h-50 sm:mr-4 lg:mr-6 overflow-hidden">
              {randomizedFilteredEmployees[1].node.profile.testimonial?.localFile && (
                <GatsbyImage
                  className="h-full"
                  image={getImage(randomizedFilteredEmployees[1].node.profile.testimonial.localFile)}
                  alt={randomizedFilteredEmployees[1].node.title}
                />
              )}
            </div>
          </div>
          <div className="col-start-2 col-span-3 sm:col-span-6 lg:col-span-5 xl:col-span-4 mt-9 sm:mt-0">
            <div className="flex fill-current text-gray-darkest" style={{ width: "20px", height: "20px" }}>
              <QuoteLeft />
            </div>
            <p
              className="careers-employee-quote mt-4"
              dangerouslySetInnerHTML={{ __html: randomizedFilteredEmployees[1].node.profile.love }}
            />
            <h3 className="text-clamp-18-26 mt-clamp-8-11">{randomizedFilteredEmployees[1].node.title}</h3>
            <h4 className="font-book font-medium text-clamp-14-22">
              {randomizedFilteredEmployees[1].node.profile.title}
            </h4>
          </div>
        </div>
      </div>
      <div className="container flex justify-center mt-clamp-18-28">
        <div data-aos="fade-right" className="lg:w-10/12 grid grid-cols-4 sm:grid-cols-min-8-1fr gap-x-2 lg:gap-x-4">
          <div className="z-1 col-span-4 sm:col-span-1 flex items-center">
            <div className="z-0 rounded-full w-38 h-38 lg:w-50 lg:h-50 sm:mr-4 lg:mr-6 overflow-hidden">
              {randomizedFilteredEmployees[2].node.profile.testimonial?.localFile && (
                <GatsbyImage
                  className="h-full"
                  image={getImage(randomizedFilteredEmployees[2].node.profile.testimonial.localFile)}
                  alt={randomizedFilteredEmployees[2].node.title}
                />
              )}
            </div>
          </div>
          <div className="col-span-3 sm:col-span-6 lg:col-span-5 xl:col-span-4 mt-9 sm:mt-0">
            <div className="flex fill-current text-gray-darkest" style={{ width: "20px", height: "20px" }}>
              <QuoteLeft />
            </div>
            <p
              className="careers-employee-quote mt-4"
              dangerouslySetInnerHTML={{ __html: randomizedFilteredEmployees[2].node.profile.love }}
            />
            <h3 className="text-clamp-18-26 mt-clamp-8-11">{randomizedFilteredEmployees[2].node.title}</h3>
            <h4 className="font-book font-medium text-clamp-14-22">
              {randomizedFilteredEmployees[2].node.profile.title}
            </h4>
          </div>
        </div>
      </div>
    </>
  )
}

const query = graphql`
  query {
    allWpTeams {
      edges {
        node {
          slug
          title
          profile {
            title
            love
            isDisplayedOnCareers
            testimonial {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
  }
`
